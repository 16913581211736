import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../Layout";
import BookPageHero from "./BookPageHero";
import ShareCard from "./ShareCard";
import BookPageTopicCard from "./BookTopicCard";
import { calculateSimilarity } from "../../utilities/utilities";
import SimilarBookCard from "./SimilarBookCard";

const BookPage = ({ data, location }) => {
  const bookData = data.airtable.data;
  let top3Books = null;

  // If topics are tagged find similar books
  // by finding books that has maximum overlap with
  // current books topics
  if (bookData.topic_tags) {
    let queryTopics = bookData.Topics__from_topic_tags_;
    let probBooks = [];

    // Get all books under the topics tags
    bookData.topic_tags.forEach(topic => {
      probBooks.push(...topic.data.book_data_complete);
    });

    // Filter Unique Books
    probBooks = probBooks.reduce(
      (map, obj) => map.set(obj.data.bookTitle, obj),
      new Map()
    );
    // Delete isntance of the current book if any
    probBooks.delete(bookData.bookTitle);
    // Convert to an array
    probBooks = [...probBooks.values()];

    // Calculate Jaqcuard Index for the other books
    for (let index = 0; index < probBooks.length; index++) {
      const element = probBooks[index];
      if (element.data.Topics__from_topic_tags_) {
        probBooks[index].similarity = calculateSimilarity(
          queryTopics,
          element.data.Topics__from_topic_tags_
        );
      } else {
        probBooks[index].similarity = 0;
      }
    }

    // Choose top 3 based on similarity
    top3Books = probBooks
      .sort((a, b) => b.similarity - a.similarity)
      .slice(0, 3);
  }

  return (
    <Layout
      pageTitle={bookData.bookTitle}
      noindex={!Boolean(bookData.descriptionRewritten)}
      nofollow={!Boolean(bookData.descriptionRewritten)}
      location={location.pathname}
    >
      <BookPageHero data={bookData} />
      <ShareCard bookTitle={bookData.bookTitle} location={location.pathname} />
      {bookData.topic_tags && <BookPageTopicCard data={bookData.topic_tags} />}
      {top3Books && <SimilarBookCard similarBooks={top3Books} />}
    </Layout>
  );
};

// Book Example of ID
// {
//   "id": "2ff95e6a-ab4d-576e-9b6f-e4897ea390ad"
// }

export const query = graphql`
  query ($id: String) {
    airtable(id: { eq: $id }) {
      data {
        Topics__from_topic_tags_
        authors
        bookCover {
          localFiles {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        description
        descriptionRewritten
        affiliateLink
        bookTitle
        published(formatString: "MMMM, YYYY")
        pages
        topic_tags {
          id
          data {
            Topics
            image_url
            topic_covers {
              localFiles {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            book_data_complete {
              id
              data {
                bookTitle
                Topics__from_topic_tags_
                bookCover {
                  localFiles {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BookPage;
