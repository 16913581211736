import * as React from "react";
import TopicsCard from "../topicComponents/TopicCards";

const BookPageTopicCard = ({ data }) => {
  return (
    <div className="card bg-base-300 shadow-lg mb-8">
      <div className="card-body gap-8">
        <h2 className="card-title text-2xl">Book Topics</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 items-stretch ">
          {data.map(bookTopic => (
            <div key={bookTopic.id}>
              <TopicsCard data={bookTopic.data} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookPageTopicCard;
