import * as React from "react";
import { useState, useMemo, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { sliceToNextSpace } from "../../utilities/utilities";

const BookPageHero = ({ data }) => {
  const [descToggle, setDescToggle] = useState(false);
  const scrollRef = useRef();
  const calculateDescription = (descriptionRewritten, description) => {
    console.log(descToggle);
    if (descToggle) {
      return descriptionRewritten
        ? descriptionRewritten
        : description
        ? description
        : "";
    } else {
      if (scrollRef && scrollRef.current /* + other conditions */) {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      }
      return descriptionRewritten
        ? sliceToNextSpace(descriptionRewritten, 300)
        : description
        ? sliceToNextSpace(description, 300)
        : "";
    }
  };
  let descriptionToShow = useMemo(
    () => calculateDescription(data.descriptionRewritten, data.description),
    [descToggle]
  );
  let toggleText = useMemo(() => {
    console.log(descToggle);
    if (descToggle) {
      return " ...Show Less";
    } else {
      return " ...Read More";
    }
  }, [descToggle]);

  return (
    <div className="book-page-hero bg-base-300 card shadow-lg flex-col md:flex-row items-center p-5 gap-3 mb-8">
      <div className="flex flex-col basis-1/3 items-center justify-items-center gap-4">
        <div className="">
          <GatsbyImage
            style={{
              height: "192px",
              width: "100%",
            }}
            imgStyle={{ borderRadius: "0.5rem" }}
            objectFit="contain"
            image={data.bookCover.localFiles[0].childImageSharp.gatsbyImageData}
            alt={data.bookTitle}
            formats={["auto", "webp", "avif"]}
          />
        </div>
        <div className="center">
          {data.affiliateLink && (
            <a
              href={data.affiliateLink}
              // Add select promotion event here
              onClick={() => {
                typeof window !== "undefined" &&
                  window.gtag("event", "select_promotion", {
                    event_label: "navigation",
                  });
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn btn-accent" aria-label="get-book">
                Get the book
              </button>
            </a>
          )}
        </div>
      </div>
      <div className="card-body md:w-2/3">
        <div>
          <h1 className="text-2xl font-bold">{data.bookTitle}</h1>
        </div>
        <div>
          <p>{data.authors.join(", ")}</p>
        </div>
        {data.pages && (
          <div>
            <p>{data.pages} pages</p>
          </div>
        )}
        <div ref={scrollRef}>
          {descriptionToShow}
          <button
            onClick={() => setDescToggle(!descToggle)}
            className="inline-block ml-1 text-accent italic"
            aria-label="read-more"
          >
            {toggleText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BookPageHero;
