import * as React from "react";
import { Link } from "gatsby";
import BookPictureCard from "./BookPictureCard";
import { slugifyWithRegex } from "../../utilities/utilities";

const SimilarBookCard = ({ similarBooks }) => {
  return (
    <div className="card bg-base-300 shadow-xl mb-8">
      <div className="card-title text-2xl px-8 pt-8">More Books Like This</div>
      <div className="card-body grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 items-stretch">
        {similarBooks.map(book => (
          <div key={book.id}>
            <Link to={`/books/${slugifyWithRegex(book.data.bookTitle)}`}>
              <BookPictureCard bookData={book.data} />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarBookCard;
