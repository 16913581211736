import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const BookPictureCard = ({ bookData }) => {
  return (
    <div className="card bg-base-200 h-full justify-items-start shadow-xl hover:scale-105">
      <div className="card-body flex-initial">
        <GatsbyImage
          style={{
            height: "192px",
            width: "100%",
          }}
          imgStyle={{ borderRadius: "0.5rem" }}
          objectFit="contain"
          image={
            bookData.bookCover.localFiles[0].childImageSharp.gatsbyImageData
          }
          alt={bookData.bookTitle}
        />
      </div>
      <div className="card-title p-5">{bookData.bookTitle}</div>
    </div>
  );
};

export default BookPictureCard;
