import * as React from "react";
import { useState } from "react";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineWhatsApp,
  AiOutlineLink,
  AiOutlineTwitter,
} from "react-icons/ai";
import { FaGetPocket, FaTelegramPlane } from "react-icons/fa";
import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  PocketShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import { cleanBookNames, copyToClipboard } from "../../utilities/utilities";
import { useSiteMetadata } from "../../utilities/siteMetadataHook";

const ShareCard = ({ bookTitle, location }) => {
  const { siteUrl } = useSiteMetadata();
  const shortBookName = cleanBookNames(bookTitle);
  const quote = `Hey! I just found this cool book called ${shortBookName}. Check it out at:`;

  let [clickAnimate, setClickAnimate] = useState(false);

  return (
    <div className="card card-body bg-base-300 py-3 px-8 mb-8 flex flex-col lg:flex-row items-center justify-between shadow-lg">
      <div className="text-lg font-medium">
        Found a cool book ? Tell a friend
      </div>
      <div className="flex gap-3 flex-row text-3xl items-center">
        <div>
          <FacebookShareButton
            url={`${siteUrl}${location}`}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "facebook",
                });
            }}
            quote={quote}
            className="hover:scale-110"
          >
            <AiFillFacebook />
          </FacebookShareButton>
        </div>
        <div>
          <LinkedinShareButton
            title={quote}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "linkedin",
                });
            }}
            url={`${siteUrl}${location}`}
            className="hover:scale-110"
          >
            <AiFillLinkedin />
          </LinkedinShareButton>
        </div>
        <div>
          <WhatsappShareButton
            url={`${siteUrl}${location}`}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "whatsapp",
                });
            }}
            title={quote}
            separator=" "
            className="hover:scale-110"
          >
            <AiOutlineWhatsApp />
          </WhatsappShareButton>
        </div>
        <div>
          <PocketShareButton
            url={`${siteUrl}${location}`}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "pocket",
                });
            }}
            title={quote}
            className="hover:scale-110"
          >
            <FaGetPocket />
          </PocketShareButton>
        </div>
        <div>
          <TwitterShareButton
            url={`${siteUrl}${location}`}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "twitter",
                });
            }}
            title={quote}
            className="hover:scale-110"
          >
            <AiOutlineTwitter />
          </TwitterShareButton>
        </div>
        <div>
          <TelegramShareButton
            url={`${siteUrl}${location}`}
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "telegram",
                });
            }}
            title={quote}
          >
            <FaTelegramPlane className="hover:scale-110" />
          </TelegramShareButton>
        </div>
        <div>
          <button
            onClick={() => {
              copyToClipboard(`${quote} ${siteUrl}${location}`);
              setClickAnimate(true);
              // Add share event here
              typeof window !== "undefined" &&
                window.gtag("event", "share", {
                  event_label: "copy_link",
                });
            }}
            className={`${clickAnimate && "animate-wiggle"} hover:scale-110`}
            aria-label="copy-link"
            onAnimationEnd={() => setClickAnimate(false)}
          >
            <AiOutlineLink />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareCard;
